import React from 'react';
import About from '../../Components/About/About';
import Counts from '../../Components/Counts/Counts';
import Mission from '../../Components/Mission/Mission';
import Directors from '../../Components/Directors/Directors';
import Gallery from '../../Components/Gallery/Gallery';
import Company from '../../Components/Company/Company';
import Summary from '../../Components/Summary/Summary';
import SummaryMobile from '../../Components/Summary/SummaryMobile';
import { useMediaQuery } from 'react-responsive';

const AboutPage = () => {
  // Define the breakpoints
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const isTabletOrDesktop = useMediaQuery({ minWidth: 768 });

  return (
    <div className='about-page' style={{ marginTop: '120px' }}>
      <About />
      <Counts />
      <Mission />
      <Directors />
      <Company />
      {isMobile && <SummaryMobile />}
      {isTabletOrDesktop && <Summary />}
      <Gallery />
    </div>
  );
};

export default AboutPage;
