import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ChooseUs.css";
import Icon1 from "../../Assets/Icons/icon1.svg";
import Icon2 from "../../Assets/Icons/icon2.svg";
import Icon3 from "../../Assets/Icons/icon3.svg";
import Icon4 from "../../Assets/Icons/icon4.svg";

const ChooseUs = () => {
  const items = [
    {
      id: 1,
      icon: Icon1,
      title: "Expertise and Experience",
      description:
        "Our team consists of highly skilled professionals with extensive experience in the telecommunication industry, ensuring high-quality service delivery.",
    },
    {
      id: 2,
      icon: Icon2,
      title: "Comprehensive Solutions",
      description:
        "We offer a full spectrum of telecommunication services, from project management to implementation and maintenance, providing a one-stop solution for all your needs.",
    },
    {
      id: 3,
      icon: Icon3,
      title: "State-of-the-Art Infrastructure",
      description:
        "Equipped with the latest technology and infrastructure, we deliver cutting-edge solutions that are both reliable and scalable.",
    },
    {
      id: 4,
      icon: Icon4,
      title: "Proven Track Record",
      description:
        "Our long-standing relationships and repeat contracts with prominent Indian companies are a testament to our reliability and excellence.",
    },
  ];

  return (
    <Container className="chooseUs-container">
      <h3 className="text-start mb-5 fw-semibold">Why choose us?</h3>
      <Row>
        {items.map((item) => (
          <Col
            key={item.id}
            sm={12}
            md={6}
            lg={6}
            className="mb-4 d-flex align-items-stretch"
          >
            <div className="chooseUs-item text-start p-3 ">
              <img src={item.icon} alt={item.title} className="icon mb-3" />
              <h4 className="fw-semibold">{item.title}</h4>
              <p>{item.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ChooseUs;
