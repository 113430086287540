import React from 'react';
import './Summary.css';
import { Container, Row, Col } from 'react-bootstrap';
import Pie1 from '../../Assets/Images/pie1.svg';
import Pie2 from '../../Assets/Images/pie2.svg';
import Pie3 from '../../Assets/Images/pie3.svg';
import Timeline from '../../Assets/Images/timeline.svg';

const Summary = () => {
  return (
    <Container className='summary-container'>
      <h3 className="text-start mb-5 fw-semibold">Summary of the financial year</h3>
      <Row>
        <div className='pie-container d-flex justify-content-between'>
          <img src={Pie1} alt='pie1' className='pie' />
          <img src={Pie2} alt='pie2' className='pie' />
          <img src={Pie3} alt='pie3' className='pie' />
        </div>
        <div className='timeline-container'>
          <img  src={Timeline} alt='timeline' className='timeline'/>
        </div>
      </Row>
    </Container>
  );
};

export default Summary;
