import React from "react";
import "./NavigationBar.css";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Link } from "react-router-dom";
import Logo from "../../Assets/Images/logo.svg";

function NavigationBar() {
  return (
    <Navbar expand="lg" className="position-absolute w-100 z-2 navbar">
      <Container>
        <Navbar.Brand className="text-dark">
          <Link to="/" className="text-decoration-none">
            <img
              className="me-2"
              src={Logo}
              alt="logo"
              style={{ transform: "rotate(1deg)" }}
            />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="text-dark" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/" className="text-dark">
              Home
            </Nav.Link>
            <Nav.Link href="/about" className="text-dark">
              About Us
            </Nav.Link>
            <Nav.Link href="/services" className="text-dark">
              Services
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
