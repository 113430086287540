import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Gallery1 from "../../Assets/Images/g1.jpg";
import Gallery2 from "../../Assets/Images/g2.jpg";
import Gallery3 from "../../Assets/Images/g3.jpg";
import Gallery4 from "../../Assets/Images/g4.jpg";
import Gallery5 from "../../Assets/Images/g5.jpg";
import Gallery6 from "../../Assets/Images/g6.jpg";

const Gallery = () => {
  const products = [
    { id: 1, image: Gallery1 },
    { id: 2, image: Gallery2 },
    { id: 3, image: Gallery3 },
    { id: 4, image: Gallery4 },
    { id: 5, image: Gallery5 },
    { id: 6, image: Gallery6 },
    
  ];

  return (
    <Container>
      <h3 className="text-start mb-5 fw-semibold ">Gallery</h3>
      <Row className="gx-4 gy-1">
        {products.map((product) => (
          <Col key={product.id} xs={12} md={4} className="mb-3">
            <div
              className="gallery-container"
              style={{ width: "100%", height: "300px", objectFit: "cover" }}
            >
              <img
                src={product.image}
                alt="gallery"
                className="img-fluid"
                style={{ width: "100%", height: "300px", objectFit: "cover",borderRadius: "10px" }}
              />
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Gallery;
