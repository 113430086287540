import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Counts = () => {
  return (
    <Container className="my-md-5">
      <Row xs={1} md={2} className="g-4">
        <Col>
          <div className="d-flex justify-content-center justify-content-md-start align-items-center text-center text-md-start">
            <h2 className="me-md-2 mb-0">100 +</h2>
            <p className="mb-0 fw-semibold">Projects Completed</p>
          </div>
          <hr className="custom-hr" />
        </Col>
        <Col>
          <div className="d-flex justify-content-center justify-content-md-start align-items-center text-center text-md-start">
            <h2 className="me-md-2 mb-0">13 +</h2>
            <p className="mb-0 fw-semibold">Years of Experience</p>
          </div>
          <hr className="custom-hr" />
        </Col>
      </Row>
    </Container>
  );
};

export default Counts;
