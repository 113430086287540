import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer-section" style={{ backgroundColor: "black" }}>
      <footer className="text-white py-4">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-4 mb-md-0 footer-col ">
              <h5>Registration Numbers</h5>
              <p className="text-light mb-4">
                Permanent Account Number (PAN) : AACCU3814D
              </p>
              <p className="text-light">
                Tax Payers Identification Number : 32151097989
              </p>
              <p className="text-light">
                PF Registration Number : KRKCH100914000
              </p>
              <p className="text-light">GST NO : 32AACCU3814D1ZV</p>
              <p className="text-light">EPF NO : KRKCH1000914000</p>
              <p className="text-light">ESI NO : 47000624890001009</p>
            </div>
            <div className="col-md-4 col-6 text-md-center ps-md-0 ps-4 footer-col ">
              <h5>Quick Links</h5>
              <p>
                <Link to="/" className="text-light text-decoration-none">
                  Home
                </Link>
              </p>
              <p>
                <Link to="/about" className="text-light text-decoration-none">
                  About Us
                </Link>
              </p>
              <p>
                <Link
                  to="/services"
                  className="text-light text-decoration-none"
                >
                  Services
                </Link>
              </p>
            </div>
            <div className="col-md-4 col-6 text-md-end ps-md-0 ps-4 footer-col">
              <h5>Follow Us</h5>
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                className="me-4 text-light"
              >
                <FaFacebook className="footer-icon" />
              </a>
              <a
                href="/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-light"
              >
                <FaInstagram className="footer-icon" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
