import React from "react";
import RectangleImg from "../../Assets/Images/Rectangle1.svg";
import Button from "react-bootstrap/Button";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <Container className="about-section mt-5">
      {/*================== About Us ================*/}
      <Row className="align-items-stretch " style={{marginBottom: "5rem"}}>
        <Col xs={12} md={4} className="order-md-2 ">
          <div className="about-img-container">
            <img
              alt="plywood-img"
              src={RectangleImg}
              className="img-fluid about-img"
            />
          </div>
        </Col>
        <Col xs={12} md={8} className="d-flex flex-column order-md-1">
          <p className="about-heading">About Us</p>
          <h3 className=" mb-4">
            Pioneering Quality in Government Civil Contracts and Telecom
            Services
          </h3>
          <p className="about-desc">
            The company looks forward to quality undertakings, and trenchant
            businesses with the Government Civil Contracts. Concurrently, the
            firm also deals with services and projects in the Telecom industry.
            The company has always striven to achieve and deliver a high
            standard in quality and essence in the Telecom industry, for
            customer satisfaction is our prime concern. Adhering to project
            timelines and taking the customer’s budget plans into account, adds
            to the merit. This enabled Anas Contractors to gain firm ground in
            the market as the ideal, preferred contractors in Kerala.
          </p>
          <Link to="/about">
            <Button
              variant="outline-primary"
              className="text-center"
              style={{ width: "200px", height: "60px", marginTop: "22px" }}
            >
              Learn More
            </Button>
          </Link>
        </Col>
      </Row>

      {/*=============== Inception & Establishment ============== */}
      <Row className="my-5 ">
        <h3 className=" mb-4">Inception & Establishment</h3>
        <p className="about-desc">
          Anas Contractors, a well-placed firm, had it’s initiation in 2011,
          under Mr. Anas M. M, director of the firm. The company looks forward
          to quality undertakings, and trenchant businesses with the Government
          Civil contracts. Concurrently, the firm also deals with services and
          projects in the Telecom Industry. The company has always strove to
          achieve and deliver a high standard in quality and essence in the
          Telecom Industry, for customer satisfaction is our prime concern.
          Adhering to project timelines and taking the customers' budget plans
          into account, adds to the merit. This enabled Anas Contractors, to
          gain a firm ground in the market, as the ideal, preferred contractors
          in Kerala.
        </p>
      </Row>
    </Container>
  );
};

export default AboutUs;
