import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Services.css";
import ServiceImg1 from "../../Assets/Images/Service1.svg";
import ServiceImg2 from "../../Assets/Images/Service2.svg";
import ServiceImg3 from "../../Assets/Images/Service3.svg";

// ===========services content =============
const servicesData = [
  {
    img: ServiceImg1,
    title: "Survey, Planning and Design",
    points: [
      "Microwave links line of site surveys and link budgets",
      "Indoor solutions for both voice and data.",
      "RF Technical Site Surveys.",
      "GSM, 3G and LTE networks planning.",
      "Fiber network solution.",
      "Site Installation drawings.",
    ],
    imgFirst: true,
  },
  {
    img: ServiceImg2,
    title: "Deployment/ Implementation",
    points: [
      "Transmission Networks (VHF, UHF, PDH and SDH microwave systems)",
      "Fiber optic and infrared networks.",
      "Installation, Integration and commissioning of GSM equipment (BTS, BSC, MSC, Repeaters)",
      "Core Networks (GGSN, SGSN, RXI, RNC)",
      "Power upgrade (UPS, Rectifiers, change over units, AC and DC boards) • Optical Multiplexer systems installation, integration, traffic transfer.",
      "Civil Works (tower erection, painting, plinths, foundation)",
      "Project management.",
      "Tower rigging works.",
    ],
    imgFirst: false,
  },
  {
    img: ServiceImg3,
    title: "Network Optimization and Testing",
    points: [
      "Site physical optimization (tilts, antenna change, azimuth)",
      "Drive testing (data collection and analysis)",
      "Software and Hardware upgrades",
      "Capacity, coverage and network performance",
    ],
    imgFirst: true,
  },
];

const Services = () => {
  return (
    <Container
      style={{ marginTop: "100px", marginBottom: "50px" }}
      className="services-page"
    >
      {/*============ Mapping each services  ============*/}
      {servicesData.map((service, index) => (
        <Row
          key={index}
          className={`service-row mb-4 justify-content-between ${
            service.imgFirst ? "flex-row" : "flex-row-reverse"
          }`}
        >
          <Col md={4} className="service-image">
            <img src={service.img} alt="img" className="img-fluid" />
          </Col>
          <Col md={7} className="service-content">
            <h3 className="fw-semibold mb-4">{service.title}</h3>
            {service.points.map((point, idx) => (
              <div key={idx}>
                <h5>{point}</h5>
                <hr className="service-divider" />
              </div>
            ))}
          </Col>
        </Row>
      ))}

      {/*============= Service bottom section =============*/}
      <Row className="bottom-row">
        <h3 className="fw-semibold mb-3">We also Undertake</h3>
        <h5>Civil and fencing</h5>
        <h5>Installation of cabin/rack</h5>
        <h5>Installation of Diesel Generator</h5>
        <h5>Installation of IBS</h5>
        <h5>Construction of foundations of tower</h5>
        <h5>Construction of fence foundations</h5>
        <h5>
          Construction of supporting walls/ retaining walls/ rooftop castings
          Accomplishments
        </h5>
      </Row>
    </Container>
  );
};

export default Services;
