import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Company = () => {
  const items = [
    {
      id: 1,
      title: "Feasibility",
      description:
        "The unique potential of the skilled teams, management and other infrastructure of the company, along with the cordial acceptance of the firm by the growing Indian companies as their trusted contractor, vouches for the company's feasibility in all its varied aspects.",
    },
    {
      id: 2,
      title: "Financial Results",
      description:
        "The financial results of the firm during the past years, show a steady growth in the turnover for the last three years. There has been a growth rate of 100% within the last three years, and the expected turnover for the current year is Rs. 10 Crores.",
    },
    {
      id: 3,
      title: "Appreciation",
      description:
        "The quality of the work completed by the firm is well appreciated by the clients and other dealers, and adds to the significant characteristics of the firm. These compliments act as an advantage in the success records of the ventures already completed.",
    },
  ];
  return (
    <Container className="items-container mt-5 mb-5">
      <h3 className="text-start mb-4 fw-semibold">
        Company Profile and Clients{" "}
      </h3>
      <Row>
        {items.map((item) => (
          <Col key={item.id} md={6} className="items-col">
            <div className="items-item mb-5">
              <h4 className="text-start fw-semibold" >{item.title}</h4>
              <div className="items-content">
                <p className="text-start" style={{fontSize:"18px"}}>{item.description}</p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Company;
