import React from "react";
import { Container, Col, Row } from "react-bootstrap";

const GetInTouch = () => {
  return (
    <>
      {/*============== Get in touch ============== */}
      <br />
      <Container>
        <Row className="p-4 mx-2 my-5 flex align-items-center border border-gray rounded">
          <Col md={6} className="p-2 my-4">
            <h4>Get in touch with us</h4>
          </Col>

          <Col md={6}>
            <Row>
              <Col md={6} className="ps-md-0">
                <div>
                  <p>Email</p>
                  <h6 className="mb-4" style={{ color: "#004468" }}>
                    <a
                      href="mailto:info@anascontractors.com"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      info@anascontractors.com
                    </a>
                  </h6>
                </div>
              </Col>

              <Col md={6}>
                <div className=" ps-md-5 text-md-center text-start ">
                  <p>Phone Number</p>
                  <h6 style={{ color: "#004468" }}>
                    <a
                      href="tel:+91 9447564163"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      +91 94475 64163
                    </a>
                    
                  </h6>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <br />
    </>
  );
};

export default GetInTouch;
