import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Mission.css";
import MissionIcon from "../../Assets/Icons/mission.svg";
import VisionIcon from "../../Assets/Icons/vision.svg";

const Mission = () => {
  const items = [
    {
      id: 1,
      icon: VisionIcon,
      title: "Our Vision",
      description:
        "We are committed to provide our customers with the best possible products and services. The company is a reliable, trustworthy, responsible, innovative, and pro-active supplier, providing efficient and helpful advice placing the customers first and foremost.",
    },
    {
      id: 2,
      icon: MissionIcon,
      title: "Our Mission",
      description:
        "With  a well trained and experienced team, the firm has never failed to offer the best telecommunication technology available in the market, along with unrivaled skills, services, and equipments that enable the customers to suit their individual needs exclusively.",
    },
  ];

  return (
    <Container className="mission-container">
      <Row>
        {items.map((item) => (
          <Col
            key={item.id}
            sm={12}
            md={6}
            lg={6}
            className="mb-4 d-flex align-items-stretch"
          >
            <div className="mission-item text-start p-3 ">
              <img
                src={item.icon}
                alt={item.title}
                className="icon mb-3"
                height={25}
              />
              <h4 className="fw-semibold">{item.title}</h4>
              <p>{item.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Mission;
