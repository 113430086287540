import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Service.css"; // Import the CSS file

const Service = () => {
  const services = [
    {
      id: 1,
      service: "Trenching and Duct laying.",
    },
    {
      id: 2,
      service: "Restoration of trenched areas.",
    },
    {
      id: 3,
      service: "OF cable pulling and copper cable laying.",
    },
    {
      id: 4,
      service: "BTS foundation works.",
    },
    {
      id: 5,
      service: "Splicing and termination of OFC.",
    },
    {
      id: 6,
      service: "Manhole casting and erection",
    },
    {
      id: 7,
      service: "Copper cable joining and termination.",
    },
    {
      id: 8,
      service: "EMF survey, and RF survey.",
    },
    {
      id: 9,
      service: "Installation of RF e-nodeB system.",
    },
    {
      id: 10,
      service: "Integration of the e-nodeB system.",
    },
    {
      id: 11,
      service: "Drive tests and Cluster optimization.",
    },
    {
      id: 12,
      service: "Building construction of PWD (Govt.of Kerala)",
    },
  ];

  return (
    <Container className="service-container">
      <h3 className="text-start mb-5 fw-semibold">Our Expert Services</h3>
      <Row>
        {services.map((service) => (
          <Col key={service.id} xs={12} md={4} className="mb-4">
            <div className="service-box">
              <p className="service-description">{service.service}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Service;
