import React from 'react'
import Timeline1 from '../../Assets/Images/timeline1.svg'
import { Container, Row, Col } from 'react-bootstrap';
import Pie1 from '../../Assets/Images/pie1.svg';
import Pie2 from '../../Assets/Images/pie2.svg';
import Pie3 from '../../Assets/Images/pie3.svg';

const SummaryMobile = () => {
  return (
    <Container className='summary-container mb-5 pb-4'>
    <h3 className="text-start mb-5 fw-semibold">Summary of the financial year</h3>
    <Row className=''>
      <div style={{width:"30%"}} >
        <img  src={Timeline1} alt='timeline' style={{height:"450px"}} className='pt-5'/>
      </div>
      <Col className='ps-0'>
        <img src={Pie1} alt='pie1' style={{width:"120px"}} />
        <h6 className='pb-4'>Annual Year 2021-2022</h6>
        <img src={Pie2} alt='pie2' style={{width:"120px"}} />
        <h6 className='pb-4'>Annual Year 2022-2023</h6>
        <img src={Pie3} alt='pie3' style={{width:"120px"}} />
        <h6>Annual Year 2023-2024</h6>
      </Col>
     
    </Row>
  </Container>
  )
}

export default SummaryMobile