import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Client1 from "../../Assets/Images/client1.svg";
import Client2 from "../../Assets/Images/client2.svg";
import Client3 from "../../Assets/Images/client3.svg";
import Client4 from "../../Assets/Images/client4.svg";
import Client5 from "../../Assets/Images/client5.svg";
import "./Clients.css";

const Clients = () => {
  return (
    <Container className="clients-container ">
      <h3 className="text-start mb-4 fw-semibold">Our Clients</h3>
      <Row className="justify-content-center justify-content-md-between">
        <Col xs={4} md={2} className="client-logo">
          <img src={Client1} alt="Client 1" className="client-image" />
        </Col>
        <Col xs={4} md={2} className="client-logo">
          <img src={Client2} alt="Client 2" className="client-image" />
        </Col>
        <Col xs={4} md={2} className="client-logo">
          <img src={Client3} alt="Client 3" className="client-image" />
        </Col>
        <Col xs={6} md={2} className="client-logo mt-4 mt-md-0">
          <img src={Client4} alt="Client 4" className="client-image" />
        </Col>
        <Col xs={6} md={2} className="client-logo mt-4 mt-md-0">
          <img src={Client5} alt="Client 5" className="client-image" />
        </Col>
      </Row>
    </Container>
  );
};

export default Clients;
