import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import NavigationBar from './Components/NavigationBar/NavigationBar';
import Footer from './Components/Footer/Footer';
import GetInTouch from './Components/GetInTouch/GetInTouch';
import AboutPage from './Pages/About/About';
import Services from './Pages/Services/Services';

function App() {
  return (
    <div className="App">
      <NavigationBar />

      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/services' element={<Services/>} /> 
      </Routes>
      
      <GetInTouch/>
      <Footer />
    </div>
  );
}

export default App;
