import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Hero.css";

const Hero = () => {
  return (
    <div className="hero-section">
      <Container>
        <Row>
          <Col md={8} className="hero-content">
            <h2 className="mb-5">Foundations for a Better Future</h2>
            <Link to="/services" >
              <Button variant="outline-light" className="view-services-button">
                View Services <FaArrowRight className="ms-2" />
              </Button>
            </Link>
            <Row className="mt-5 counts">
              <Col xs={12} sm={6}  className="mb-3 mb-sm-0">
                <h2>10K+</h2>
                <p>Happy Customers</p>
              </Col>
              <Col xs={12} sm={6} >
                <h2 className="count">13+</h2>
                <p>Years of Experience</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
