import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Statements.css";
import Tic from "../../Assets/Images/tic.svg";

const Statements = () => {
  const statements = [
    {
      id: 1,
      description:
        "Ensuring rapt attention to customers requirements and guaranteeing that these quality expectations are clearly defined, understood, and met.",
    },
    {
      id: 2,
      description:
        "Prompt actions to meet the customers' needs, aiming to exceed expectations in terms of amenity, merit, and efficiency.",
    },
    {
      id: 3,
      description:
        "Assuring the safety of our employees, operations, and the environment at large. UMA CONSTRUCTION AND NETWORKING SOLUTIONS PRIVATE LIMITED excel as one of the best networking solutions providers in Kerala, for our focus on design, development, installations, testing and commissioning, optimization, maintenance, training and technical support, remains unparalleled.",
    },
  ];

  return (
    <Container className="statements-container">
      <h3 className="text-start mb-4 fw-semibold">
        The company’s Quality Statement Foregrounds
      </h3>
      <Row>
        {statements.map((statement) => (
          <Col key={statement.id} md={6} className="statements-col">
            <div className="statements-item">
              <div className="statements-icon">
                <img src={Tic} alt="Tic icon" width={25} height={25} />
              </div>
              <div className="statements-content">
                <p className="text-start">{statement.description}</p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Statements;
