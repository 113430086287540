import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import RectangleImg from "../../Assets/Images/Rectangle1.svg";

const About = () => {
  return (
    <Container>
      {/*================== About Us ================*/}
      <Row className="align-items-stretch " style={{ marginBottom: "4rem" }}>
        <Col xs={12} md={4} className="order-md-2 ">
          <div className="about-img-container">
            <img
              alt="plywood-img"
              src={RectangleImg}
              className="img-fluid about-img"
              style={{ width: "60%" }}
            />
          </div>
        </Col>
        <Col xs={12} md={8} className="d-flex flex-column order-md-1">
          <p className="about-heading">About Us</p>
          <h3 className="about-title mb-4">
            Leading Quality and Customer Satisfaction in Civil and Telecom
            Projects
          </h3>
          <p className="about-desc">
            With a commitment to excellence, we deliver top-tier civil and
            telecom projects that meet the highest industry standards. Our
            dedicated team ensures that every project is executed with
            precision, innovation, and a focus on customer satisfaction. We
            pride ourselves on our ability to provide tailored solutions that
            exceed client expectations, fostering long-term partnerships built
            on trust and reliability. 
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default About;
