import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./Tools.css";
import Img1 from "../../Assets/Images/img1.svg";
import Img2 from "../../Assets/Images/img2.svg";

const Tools = () => {
  return (
    <Container  className="tools-container">
      <h3 className="text-start mb-5 fw-semibold">Our Machines & Equipments</h3>
      <Row className="tools-row">
        {/* Image section  */}
        <Col className="image-col">
          <div className="image-container">
            <img src={Img1} alt="Machine 1" className="img1" />
            <img src={Img2} alt="Machine 2" className="img2" />
          </div>
        </Col>
        {/* Text section */}
        <Col className="texts">
          <p>Temporary Lifts</p>
          <p>Concrete Mixer Machine</p>
          <p>Compressor</p>
          <p>JCB</p>
          <p>Motors</p>
          <p>Multimeters</p>
          <p>Sufficient Loose Tools</p>
          <p>Sufficient PPE kits</p>
        </Col>
      </Row>
    </Container>
  );
};

export default Tools;
