import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Director1 from "../../Assets/Images/anas.jpg";
import Director2 from "../../Assets/Images/azeez.jpg";

const directors = [
  { id: 1, name: "Anas MM", image: Director1 },
  { id: 2, name: "Azeez MM", image: Director2 },
];

const Directors = () => {
  return (
    <Container className="mt-5 mb-5">
      <h3 className="text-md-start text-center mb-5 fw-semibold">
        Our Directors
      </h3>
      <Row className="justify-content-md-start">
        {directors.map((director) => (
          <Col key={director.id} xs={12} md={4} className="mb-4 text-center">
            <img
              src={director.image}
              alt={director.name}
              className="rounded-circle  mb-3"
              style={{ width: "180px", height: "180px", objectFit: "cover" }}
            />
            <p>{director.name}</p>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Directors;
