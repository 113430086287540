import React from 'react';
import ChooseUs from '../../Components/ChooseUs/ChooseUs';
import Hero from '../../Components/Hero/Hero';
import Clients from '../../Components/Clients/Clients';
import AboutUs from '../../Components/AboutUs/AboutUs';
import Statements from '../../Components/Statements/Statements';
import Service from '../../Components/Service/Service';
import Tools from '../../Components/Tools/Tools';


function Home() {
    return (
        <div className='home-page'>
            <Hero/>
            <AboutUs/>
            <Statements/>
            <Service/>
            <ChooseUs/>
            <Tools/>
            <Clients/>
        </div>
    )
}

export default Home;